@use "../config/" as *;
.blue-bg {
    @extend %c-blue-bg-10;
}
.featured-list {
    background: url(/static-assets/images/cssimages/listing-bg.png?v=#{$image-version}) no-repeat;
    .waf-head {
        @extend %m-b-two-space;
        .title {
            @extend %c-white-10;
        }
        .head-tab {
            @include position(null, 0, unset, unset, relative);
            @include translate(0, 0);
        }
    }
    .waf-listing {
        @extend %m-x-half-neg;
        @extend %m-t-three-space;
        .layout-wrapper {
            &:after {
                content: "";
                display: block;
                width: 90%;
                height: 0.1rem;
                bottom: 0;
                @extend %c-white-bg-4;
                @extend %position-h-center;
            }
        }
        .article {
            &-list {
                gap: var(--full-space);
            }
            &-title {
                font-size: 2rem;
                line-height: 1.2;
                height: 4.8rem;
                font-weight: 400;
            }
            &-info .tagline {
                padding-right: var(--full-space);
                @extend %relative;
                &::after {
                    content: "";
                    width: 0.1rem;
                    height: 60%;
                    right: var(--half-space);
                    @extend %d-block;
                    @extend %c-white-bg-6;
                    @extend %position-v-center;
                }
            }
        }
        .swiper-button {
            &-prev, &-next {
                @extend %d-none;
            }
        }
    }
    .waf-home-listing {
        @extend %p-r-zero;
        .layout-wrapper::after {
            content: unset;
        }
        .waf-head {
            @extend %p-r-full;
        }
        .article-list {
            box-sizing: border-box;
            @extend %p-r-full;
        }
        .article-title {
            @extend %m-b-half;
        }
        .article-meta {
            flex-direction: row;
            .meta-date::before {
                content: unset;
            }
        }
    }
}
.waf-listing {
    @extend %m-x-half-neg;
    @extend %m-y-zero;
    .layout-wrapper {
        @extend %p-b-three-space;
    }
    .article {
        &-list {
            overflow-x: auto;
            overflow-y: hidden;
            @include card-count(1.2, var(--half-space), nowrap);
        }
        &-item {
            &:hover {
                img {
                    @include transform(scale(1.1));
                }
            }
        }
        &-thumbnail {
            @include border-radius(var(--half-radius), hidden);
            @include border(1, c-white, 7);
            &:hover {
                .item-type-icon {
                    &::before {
                        display: block;
                        transition-delay: 6s;
                        @include translate(0, 0);
                    }
                    &::after {
                        @include translate(-6.5rem, 0);
                        @include transition(0.1s ease-in);
                    }
                }
            }
            .img-box {
                @include border-radius(unset);
            }
            img {
                @include transition(500ms ease-in-out);
                object-fit: cover;
            }
        }
        &-content {
            flex-wrap: wrap;
            @extend %flex;
            > a {
                @extend %w-100;
            }
            .tagline {
                order: 1;
                p {
                    line-height: 1;
                }
            }
        }
        &-info {
            order: 1;
            @extend %c-orange-10;
        }
        &-title {
            @include truncate-vertical(2, 16, null, $font-pr);
            @extend %c-white-10;
            @extend %m-b-zero;
        }
        &-meta {
            order: 3;
            @include flex-config(flex, row-reverse, null, center);
            .meta {
                @extend %c-orange-10;
                @extend %font-14-pr;
                &-date {
                    position: relative;
                    line-height: 1;
                    @extend %c-white-6;
                    @extend %font-12-pr;
                    &:before {
                        content: "";
                        width: 0.1rem;
                        height: 60%;
                        left: 0;
                        @extend %d-block;
                        @extend %c-white-bg-6;
                        @extend %position-v-center;
                    }
                }
            }
        }
        .count {
            @extend %c-white-6
        }
        &-readmore {
            @include position(null, var(--full-space), var(--full-space));
        }
    }
    .reaction-section {
        @extend %m-r-full;
    }
    .swiper {
        &-button {
            @extend %d-none;
        }
    }
    .count {
        @extend %c-white-10;
    }
    .featured-item::after {
        content: "\E857";
        width: 2.4rem;
        aspect-ratio: 1;
        border-radius: .4rem;
        background-color: hsl(var(--hsl-c-blue-3)/.6);
        font: 1.7rem/1 $font-icon;
        @include position(1rem, null, null, 1rem);
        @extend %flex-c-c;
        @extend %c-pure-white-10;
    }
    .item-type-video .article-thumbnail {
        .item-type-icon {
            @include position(1.2rem, 1.2rem);
            &:after {
                @include icon("\e814");
                @include font(25);
                @include transition(0.3s ease-in);
                line-height: 1;
                @extend %c-white-9;
                @extend %d-block;
            }
            &:before {
                content: "Watch";
                line-height: 1;
                @include position(.4rem, 1.4rem);
                @extend %d-none;
                @extend %c-white-10;
                @extend %font-14-pb;
            }
        }
    }
}
.rank-list-wrapper {
    @extend %head-position;
    .waf-listing {
        @extend %p-r-zero;
        @extend %m-x-half-neg;
        @extend %m-b-three-space;
        .second-list {
            @extend %d-none;
        }
        .first-list {
            overflow: auto;
            @include flex-config(flex, row);
            @extend %p-r-full;
        }
        .article-title {
            font-size: 2rem;
            line-height: 2.4rem;
            height: 4.8rem;
            @extend %m-b-half;
        }
    }
    .article-item {
        border-radius: 0 0 var(--half-radius) var(--half-radius);
    }
    @include overlay();
}
.videos {
    @extend %m-zero;
}
.horizontal-list,
.institutional-list {
    @extend %m-zero;
    .article {
        &-list {
            flex-direction: column;
        }
        &-wrap {
            @extend %flex;
        }
        &-thumbnail {
            border: 0;
            width: clamp(10.3rem, 30%, 21.3rem);
            flex-shrink: 0;
            .img-box {
                @include border-radius(var(--half-radius), hidden);
            }
            img {
                @include transition(300ms);
            }
        }
        &-content {
            @extend %w-100;
            @extend %p-half;
        }
        &-title {
            font-weight: 400;
            @include truncate-vertical(3, 14, null, $font-pr);
            @extend %w-100;
        }
        &-info {
            order: unset;
        }
        &-item {
            position: relative;
            width: 100%;
            @include transition(300ms);
            @extend %half-radius;
            @extend %c-white-bg-1;
            @extend %m-y-full;
            &:hover {
                @extend %c-white-bg-3;
                .article-thumbnail {
                    img {
                        scale: 1.1;
                        @include transform(scale(1.1));
                    }
                }
            }
            .readmore {
                @include position-combo(inset);
                font-size: 0;
            }
            > a {
                @extend %d-block;
                @extend %w-100;
            }
        }
        &-meta {
            margin-top: auto;
            .meta-date {
                opacity: 0.4;
                padding-left: 0;
                margin-left: 0;
                padding-right: var(--half-space);
                &::before {
                    content: unset;
                }
            }
        }
        &-readmore {
            @include position-combo(inset);
        }
    }
    .reaction-section {
        order: 4;
        margin-top: auto;
        opacity: 0.4;
        @extend %flex;
        @extend %c-white-10;
        &::before {
            content: '|';
            @extend %m-r-quarter;
        }
    }
    .img-box {
        @extend %h-100;
    }
    .swiper-button-prev,
    .swiper-button-next {
        @extend %d-none;
    }
}
.institutional-list {
    .article {
        &-thumbnail {
            @extend %d-none;
        }
        &-title {
            @include truncate-vertical(1, 16, null, $font-pr);
        }
    }
}
.listing-page {
    background: transparent;
    @extend %white-bg;
    &.rank-list-wrapper {
        &::before,
        &::after {
            content: unset;
        }
        .reaction-section {
            @extend %d-none;
        }
    }
    .article {
        &-list {
            flex-direction: column;
        }
        &-item {
            width: 100%;
            border-radius: 1.2rem;
        }
    }
    .waf-listing {
        @extend %p-x-half;
        .first-list {
            flex-direction: column;
        }
    }
}
.match-center {
    .waf-listing {
        .article {
            &-title {
                @extend %c-blue-10;
            }
            &-meta {
                .meta-date {
                    @extend %c-blue-6;
                }
            }
        }
    }
    .featured-list {
        background: transparent;
        .waf-head {
            .title {
                @extend %c-blue-10;
                @extend %font-16-pb;
            }
        }
        .waf-listing {
            .article-list {
                gap: 0;
            }
            .swiper {
                &::after {
                    content: unset;
                }
                &-button-next,
                &-button-prev {
                    display: none;
                }
            }
            .meta-date {
                &::before {
                    @extend %c-blue-bg-6;
                }
            }
        }
    }
    .horizontal-list {
        .waf-body {
            margin-top: var(--full-space);
        }
        .article {
            &-list {
                gap: var(--full-space);
            }
            &-item {
                box-shadow: 0 0.5rem 2rem 0 hsla(var(--hsl-c-black)/0.1);
                margin-block: 0;
                @extend %c-white-bg-10;
            }
            &-content {
                a {
                    &::before {
                        @extend %c-blue-bg-10;
                    }
                }
            }
            &-readmore {
                @extend %d-none;
            }
        }
        .views-section {
            .count {
                @extend %c-blue-6;
            }
        }
    }
}
.watch-center {
    .featured-list {
        background: var(--c-blue);
        .article-thumbnail {
            border: 0;
        }
        .article-title {
            font-size: 1.2rem;
            -webkit-line-clamp: 1;
            height: auto;
        }
        .article-meta .meta-date::before {
            content: unset;
        }
    }
    .event-masthead {
        .event-content {
            .event {
                @extend %m-l-zero;
            }
        }
    }
}
.past-video-listing {
    @extend %c-blue-3-bg-10;
    .waf-listing {
        @extend %p-x-zero;
    }
    .waf-head {
        @extend %p-full;
        .title {
            @extend %c-white-10;
        }
    }
    .article {
        &-list {
            flex-wrap: wrap;
            gap: 0;
        }
        &-item {
            width: 100%;
            margin-bottom: var(--one-n-half-space);
            @extend %relative;
        }
        &-thumbnail {
            border-radius: 0;
        }
        &-content {
            @extend %p-x-full;
        }
    }
}
.rank-list-section {
    background: url(/static-assets/images/cssimages/ranking-bg.png?v=#{$image-version}) center / cover no-repeat;
    .latest-update {
        .first-list {
            overflow-x: auto;
            overflow-y: hidden;
            .article-thumbnail {
                .tagline {
                    display: none;
                }
            }
        }
        .article-item {
            border: .1rem solid transparent;
            isolation: isolate;
            overflow: unset;
            &::before {
                content: "";
                position: absolute;
                z-index: var(--zindex-pattern);
                inset: -0.1rem;
                background: linear-gradient(145.98deg, var(--c-white) -1.55%, hsl(var(--hsl-c-white) / .2) 50.97%, var(--c-white) 102.72%);
                @extend %half-radius;
            }
        }
    }
    .rank-list-wrapper .article-item::after {
        border-radius: var(--half-radius);
    }
}
.main-listing-page .waf-listing .reaction-section {
    display: none;
}
.homepage .waf-listing .reaction-section {
    display: none;
}
@media (min-width: $tablet-min-width) {
    .rank-list-section {
        // .site-content {
        //     height: 100%;
        // }
        .container {
            padding: 0;
        }
        .latest-update {
            .first-list {
                overflow: unset;
                .article-title {
                    -webkit-line-clamp: 4;
                }
                .article-thumbnail {
                    .tagline {
                        display: block;
                        @include position(var(--full-space), null, null, var(--full-space));
                    }
                }
                .article-content {
                    .tagline {
                        display: none;
                    }
                }
            }
            .second-list {
                .article-thumbnail {
                    .tagline {
                        display: none;
                    }
                }
            }
        }
    }
    .rank-list-wrapper {
        position: relative;
        background: unset;
        // max-width: var(--container-max-width);
        // margin: auto;
        .layout-wrapper {
            max-width: 100%;
        }
        .waf-listing .first-list {
            padding-right: 0;
        }
    }
    .waf-listing {
        padding: 0;
        margin: 0;
        .article {
            &-list {
                overflow: unset;
                position: relative;
            }
        }
        .featured-item::after {
            left: 1.2rem;
        }
        .swiper {
            position: relative;
            &::after {
                content: "";
                width: 20rem;
                height: 65%;
                z-index: var(--zindex1);
                pointer-events: none;
                @include linear-gradient(268.67deg, hsl(var(--hsl-c-blue)/0.6) 56.77%, hsl(var(--hsl-c-blue)/0) 100%);
                @include position-combo(tr);
            }
            &-button-prev,
            &-button-next {
                display: block;
                @include translate(0, -100%);
                @include position(50%);
                &:after {
                    @include color(c-pure-white);
                    @include font(42);
                }
            }
        }
    }
    .rank-list-wrapper {
        .waf-listing {
            // height: calc(100% - 18.3rem);
            margin: var(--three-space) 0 13.5rem;
            .layout-wrapper {
                // height: 100%;
                padding-bottom: 0;
                @include flex-config(flex, column);
            }
            .waf-body {
                // flex-grow: 1;
                @include flex-config(flex, column);
            }
            .article-item {
                width: 100%;
                &:hover {
                    .article-readmore {
                        opacity: 1;
                    }
                }
            }
            .article-thumbnail {
                border: 0;
            }
            .first-list {
                .article-title {
                    width: 75%;
                    height: auto;
                    font-family: $font-pb;
                    font-size: 4.8rem;
                    line-height: 5.8rem;
                }
                .swiper-button {
                    display: none;
                }
            }
            .second-list {
                // margin-top: auto;
                margin-top: var(--full-space);
                @include grid(repeat(3, 1fr));
            }
            .waf-head {
                .head-tab {
                    left: auto;
                    transform: translate(0);
                    // @include position-combo(br);
                    @include position(null, 0, -4rem); //there is something height issue 
                }
            }
            .article-readmore {
                opacity: 0;
            }
        }
    }
    .featured-list {
        .waf-listing {
            margin-block: calc(5 * var(--full-space)) 0;
            .layout-wrapper {
                // margin-left: calc(var(--container-white-space));
                max-width: 100%;
                // .rank-list-wrapper
                &::after {
                    width: calc(var(--container-max-width) - var(--container-white-space));
                    left: var(--container-white-space);
                    @include translate(0, 0);
                }
            }
            .waf-head {
                max-width: var(--container-max-width);
                margin: 0 var(--container-white-space) var(--two-space) 0;
            }
            .swiper {
                &::before {
                    content: "";
                    width: 20rem;
                    height: 100%;
                    z-index: var(--zindex-featured-list-gradient);
                    pointer-events: none;
                    @include linear-gradient(-268.67deg, hsl(var(--hsl-c-blue)/0.6) 56.77%, hsl(var(--hsl-c-blue)/0) 100%);
                    @include position(0, null, null, calc(-1 * var(--container-white-space)));
                }
                &::after {
                    height: 100%;
                }
                &-button-prev, &-button-next {
                    display: block;
                }
                &-button-prev {
                    left: -1.5rem;
                }
                &-button-next {
                    right: var(--container-white-space);
                }
            }
            .article-title {
                margin-bottom: var(--half-space);
            }
            .article-list {
                gap: unset;
            }
        }
        .waf-home-listing {
            .waf-head, .article-list {
                padding-right: 0;
            }
            .article-list {
                box-sizing: content-box;
            }
        }
    }
    .horizontal-list,
    .institutional-list {
        .article-content {
            a {
                position: relative;
                @include color(c-white);
                display: flex;
                &:before {
                    content: '';
                    width: 2.4rem;
                    aspect-ratio: 1/1;
                    right: 0;
                    display: block;
                    @include position-combo(y-center);
                    @include border-radius(50%);
                    @include bg(c-white, 2);
                }
                &::after {
                    width: 2.4rem;
                    aspect-ratio: 1/1;
                    right: 0;
                    @include position-combo(y-center);
                    @include flex-config(flex, null, center, center);
                    @include icon('\e801');
                }
            }
        }
        .swiper {
            &::after {
                content: unset;
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }
    .horizontal-list {
        .article {
            &-item {
                padding: var(--full-space);
            }
            &-title {
                @include truncate-vertical(3, 16);
            }
        }
    }
    .listing-page {
        @include white-bg();
        &.rank-list-wrapper {
            .first-list {
                flex-direction: row;
                flex-wrap: wrap;
                overflow: unset;
                @include card-count(3, var(--half-space));
                .article-title {
                    // @include truncate-vertical(2, 24,null,$font-pb);
                    font-size: 2.4rem;
                    height: auto;
                    width: 100%;
                    line-height: 30px;
                }
            }
            .article-item {
                &:nth-child(10n + 1) {
                    width: 100%;
                    height: 60rem;
                    transition: 1s ease-in-out;
                    .img-box {
                        height: 60rem;
                    }
                    .article-title {
                        height: auto;
                        width: 80%;
                    }
                }
            }
            .article-readmore {
                font-family: $font-pb;
            }
            .article-title {
                -webkit-line-clamp: 4;
            }
        }
        .filter-section {
            .filter {
                &-label-group,
                &-hide-data {
                    display: none;
                }
                &-search {
                    right: var(--container-white-space);
                }
            }
        }
    }
    .match-center {
        .horizontal-list {
            .article {
                &-wrap {
                    align-items: center;
                }
                &-content {
                    padding-inline: var(--full-space);
                    position: relative;
                    align-items: center;
                    a::after,
                    a::before {
                        content: unset;
                    }
                    &::before {
                        content: "\e801";
                        width: 2.4rem;
                        height: 2.4rem;
                        right: var(--two-space);
                        display: block;
                        font-size: 1.6rem;
                        font-family: $font-icon;
                        @include color(c-white, 10);
                        @include flex-config(flex, null, center, center);
                        @include border-radius(50%);
                        @include position-combo('y-center');
                        @include bg(c-blue, 10);
                    }
                }
                &-title {
                    height: auto;
                    width: 90%;
                }
                &-meta {
                    margin-top: var(--full-space);
                    padding-left: 0;
                    // padding-left: var(--half-space);
                }
            }
        }
    }
    .watch-center {
        .featured-list {
            .waf-listing {
                margin-top: 0;
                .layout-wrapper {
                    padding-block: 3.7rem var(--two-space);
                    &::after {
                        content: unset;
                    }
                }
            }
            .article-title {
                font-size: 2rem;
                -webkit-line-clamp: 2;
            }
        }
        .event-masthead {
            .event-content {
                .event {
                    margin-left: calc(var(--half-space) + var(--quarter-space));
                }
            }
        }
    }
    .homepage .featured-list .swiper::after {
        height: 100%;
    }
    .past-video-listing {
        .waf-listing {
            .layout-wrapper {
                max-width: 100%;
                margin-left: var(--two-space);
            }
        }
        .waf-head {
            padding-inline: 0;
        }
        .article {
            &-list {
                gap: calc(var(--quarter-space) + var(--half-space));
            }
            &-item {
                width: calc(33.33% - calc(var(--quarter-space) + var(--half-space)));
            }
            &-thumbnail {
                border-radius: var(--half-space);
            }
            &-content {
                padding-inline: 0;
            }
        }
    }
}
@media (min-width:$xl-desktop-min-width) {
    .rank-list-wrapper {
        .waf-listing {
            .second-list {
                .article-title {
                    font-size: 2.4rem;
                    line-height: 3rem;
                    height: auto;
                }
            }
        }
    }
}
@media (min-width:$large-desktop-min-width) {
    .rank-list-wrapper {
        position: relative;
        max-width: var(--container-max-width);
        margin: auto;
    }
    .featured-list {
        .waf-listing {
            .layout-wrapper {
                margin-left: calc(var(--container-white-space));
            }
            .swiper {
                padding-right: var(--container-white-space);
            }
        }
    }
}