@use "../config" as *;
html {
  &.no-scroll,
  &.no-scroll body {
    width: 100%;
    height: var(--window-inner-height);
    touch-action: none;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow: hidden;
  }
}
[v-cloak] {
  visibility: hidden;
}
ul {
  list-style: none;
}
.grecaptcha-badge {
  visibility: hidden;
}
.waf-row {
  clear: both;
  position: relative;
  &::before,
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}
.waf-row:before,
.waf-row:after {
  clear: both;
  content: "";
  display: table;
}
.waf-component {
  position: relative;
  display: block;
  padding: var(--full-space);
  margin: var(--half-space) 0;
}
.video-player-33 {
  overflow: hidden;
  @extend %c-shimmer-light-bg-10;
  @extend %half-radius;
}
.img-box {
  overflow: hidden;
  // @extend %c-shimmer-light-bg-10;
  // @extend %half-radius;
  img {
    height: auto;
    width: 100%;
  }
}
img {
  height: auto;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
}
.img-4by3,
.img-16by9,
.img-1by1,
.img-2by3,
.img-9by16,
.img-21by9,
.img-18by9,
.img-18by6,
.img-6by4 {
  .img-box {
    position: relative;
    @include shimmer();
    img {
      width: 100%;
      height: 100%;
      @include position(0, null, null, 0);
      &[alt] {
        font-size: 1.2rem;
        color: hsl(var(--c-black-hsl)/ 7);
      }
    }
  }
}
html body #onetrust-pc-sdk.otRelFont,
html body #onetrust-banner-sdk.otFloatingRoundedIcon.otRelFont {
  font-size: 1.6rem;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@each $width,
$height in $aspect-ratio {
  .img-#{$width}by#{$height} .img-box {
    padding-bottom: calc(($height / $width) * 100%);
  }
}
.desktop {
  display: none;
}
.mobile {
  display: block;
}
@each $width in $flex-width {
  .m-flex-#{$width} {
    flex-basis: calc($width * 1%);
    width: calc($width * 1%);
    &-gap {
      flex-basis: calc((#{$width} - var(--quater-space)) * 1%);
      width: calc((#{$width} - var(--quater-space)) * 1%);
    }
  }
}
//project css start
/* web setup css done */
/* project common css start */
.main-wrap {
  padding-top: var(--header-height);
}
.layout-wrapper {
  max-width: var(--container-max-width);
  margin: auto;
}
.new-line {
  @extend %d-block;
}
.bold {
  font-family: $font-pb;
}
.italic {
  font-style: italic;
}
/* project common css end */
.body-wrap {
  min-height: calc(var(--window-inner-height) - var(--header-height));
}
.title {
  @extend %font-20-pb;
  @extend %c-blue-10;
  @extend %m-zero;
}
// .input-group{
@include input(text);
@include input('radio');
@include input('checkbox');
@include input(email);
@include input(password);
@include input(select);
.card,
.player,
.poll {
  &-thumbnail {
    position: relative;
    flex-shrink: 0;
    @extend %flex;
    .image {
      width: 4rem;
      height: 4rem;
      @extend %ratio-1-1;
      @extend %circle-radius;
      @extend %c-black-bg-2;
    }
    .logo {
      width: 1.5rem;
      height: 1.5rem;
      @extend %ratio-1-1;
      @extend %circle-radius;
      @extend %position-b-r;
    }
  }
}
.waf-head {
  .head-wrap {
    @include flex-config(flex, null, space-between, center);
  }
  a {
    @extend %c-orange-10;
    @extend %font-16-pb;
  }
}
.waf-no-data {
  display: none;
}
.no-data-available {
  .body-wrap {
    @extend %d-none;
  }
  .main-wrap {
    height: var(--window-inner-height);
  }
  .waf-no-data {
    display: block;
    position: absolute;
    inset: 20%;
    top: 50%;
    @include translate(0, -50%);
  }
}
.accordion {
  &-item {
    @extend %m-b-full;
    @extend %p-x-full;
    @extend %relative;
    &::after {
      content: '';
      height: .1rem;
      bottom: var(--half-space-negative);
      @extend %w-100;
      @extend %c-white-bg-5;
      @extend %position-b-l;
    }
  }
  &-header {
    @extend %flex-sb-c;
    @extend %text-left;
    @extend %c-white-8;
    @extend %w-100;
    &::after {
      @include icon('\e806', 22);
    }
    .btn-text {
      @extend %font-14-pr;
    }
    &[aria-expanded="true"] {
      &::after {
        @include icon('\e802', 22);
      }
      ~ .accordion-body {
        grid-template-rows: 1fr;
      }
    }
  }
  &-content {
    overflow: hidden;
    @extend %c-white-10;
    @extend %font-12-pr;
  }
  &-body {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.5s ease-out;
  }
}
.modal {
  &.YouTubeModal {
    position: fixed;
    inset: 0;
    z-index: var(--zindex-modal);
    @extend %c-blue-bg-9;
    @extend %d-none;
    &.active {
      @extend %flex-c-c;
    }
  }
}
.YouTubeModal {
  .modal {
    &-wrapper {
      @extend %p-full;
      @extend %c-blue-bg-10;
      @extend %w-100;
    }
    &-body {
      max-height: calc(var(--window-inner-height) - 17rem);
      @extend %flex-c-c;
      @extend %h-100;
      @extend %relative;
      @extend %flex-column;
    }
  }
  .back-close {
    width: 3rem;
    height: 3rem;
    z-index: var(--zindex1);
    @extend %circle-radius;
    @extend %flex-c-c;
    @extend %c-white-bg-10;
    @include position(calc(var(--one-n-half-space)*-1), var(--full-space-negative), null, null);
    &::before {
      content: '\e804';
      position: absolute;
      font-family: $font-icon;
      font-weight: 900;
      @include font(16);
      @extend %c-blue-10;
    }
    .btn-text {
      @extend %font-zero;
    }
  }
  .video-section {
    @extend %w-100;
    @extend %h-100;
  }
}
.YouTubeModal {
  .modal {
    &-wrapper {
      @extend %p-full;
      @extend %c-blue-bg-10;
      @extend %w-100;
    }
    &-body {
      @extend %relative;
      @extend %flex-column;
    }
  }
  .back-close {
    width: 3rem;
    height: 3rem;
    z-index: var(--zindex1);
    @extend %circle-radius;
    @extend %flex-c-c;
    @extend %c-white-bg-10;
    @include position(calc(var(--one-n-half-space)*-1), var(--full-space-negative), null, null);
    &::before {
      content: '\e804';
      position: absolute;
      font-family: $font-icon;
      font-weight: 900;
      @include font(16);
      @extend %c-blue-10;
    }
    .btn-text {
      @extend %font-zero;
    }
  }
  .video-section {
    @extend %w-100;
    @extend %h-100;
  }
}
// .waf-search{
//   display: none;
// }
.waf-component {
  &.waf-loader,
  &.waf-search-loader {
    position: fixed;
    left: 0;
    @include flex-config(none, null, center, center);
    @extend %w-100;
    @extend %m-zero;
    @extend %c-white-bg-6;
    .loader {
      width: 15rem;
      height: 15rem;
      @extend %m-x-auto;
      @extend %p-full;
      @extend %relative;
      @extend %flex-c-c;
      @include border-radius(50%, visible);
    }
  }
  &.waf-loader {
    height: var(--window-inner-height);
    z-index: var(--zindex-loader);
    top: 0;
    -webkit-backdrop-filter: blur(3rem);
    backdrop-filter: blur(3rem);
  }
  &.waf-search-loader {
    z-index: var(--zindex-search-loader);
    top: calc(var(--header-height) + var(--full-space) + 11.5rem);
    bottom: 0;
    background-color: var(--c-blue);
  }
}
.homepage {
  .waf-component.waf-search-loader {
    top: calc(var(--eventstrip) + var(--header-height) + var(--full-space) + 11.5rem);
  }
  &.no-live-event .waf-component.waf-search-loader {
    top: calc(var(--header-height) + var(--full-space) + 11.5rem);
  }
}
.past-match .body-wrap {
  min-height: unset;
}
.modal {
  &.successModal {
    position: fixed;
    inset: 0;
    z-index: var(--zindex-modal);
    @extend %c-blue-bg-9;
    @extend %d-none;
    &.active {
      @extend %flex-fs-n;
    }
  }
}
.successModal {
  &::before {
    content: "";
    z-index: var(--zindex-overlay);
    background: url('/static-assets/images/cssimages/login-bg.jpg?v=#{$image-version}') no-repeat;
    background-size: cover;
    background-attachment: fixed;
    inset: 0;
    position: fixed;
    @extend %c-black-bg-3;
    @extend %d-block;
  }
  .modal {
    &-wrapper {
      @extend %w-100;
    }
    &-head {
      @extend %flex-sb-c;
      .head-logo {
        width: 5.5rem;
        height: 6.5rem;
      }
      .btn-text {
        @include flex-config(flex, row-reverse, null, center);
        @extend %font-14-pr;
        &:after {
          content: '\e800';
        }
      }
    }
    &-body {
      max-height: unset;
      flex-direction: column;
      -webkit-backdrop-filter: blur(2rem);
      backdrop-filter: blur(2rem);
      height: auto;
      @extend %text-center;
      @extend %m-full;
      @extend %c-white-bg-1;
      @extend %p-x-full;
      @extend %p-y-two-space;
      @extend %full-radius;
      @include border(1, c-white, 2);
      .form-title,
      .form-desc {
        @extend %c-white-10;
      }
      .form-title {
        @extend %uppercase;
        @extend %font-20-sb;
        @extend %m-y-full;
      }
      .form-logo {
        width: 5rem;
        height: 5rem;
      }
      .form-action {
        @extend %m-t-two-space;
        .btn-outline {
          border-radius: var(--two-space);
          border: .1rem solid var(--c-white);
          @extend %c-blue-10;
          @extend %c-white-bg-10;
          @extend %p-x-full;
          @extend %p-y-half;
          &:hover {
            @extend %c-white-10;
            @include bg(transparent);
          }
        }
      }
    }
  }
}
.waf-modal-popup {
  z-index: var(--zindex-modal);
  background-color: hsl(var(--hsl-c-black) / .95);
  @include position-combo(inset, null, null, fixed);
  @extend %d-none;
  &.active {
    @extend %flex-c-c;
  }
  .modal {
    &-wrapper {
      padding: 2rem;
      @extend %c-cetacean-blue-bg-10;
      @extend %text-center;
    }
    &-title {
      margin-block: 0 var(--one-n-half-space);
      @extend %c-pure-white-10;
      @extend %font-16-pb;
    }
    &-text {
      margin-block: 0 var(--one-n-half-space);
      @extend %c-white-10;
      @extend %font-14-pr;
    }
    &-btn-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @extend %gap-full;
    }
    &-btn {
      height: 4rem;
      padding-block: .4rem;
      border: .1rem solid var(--c-white);
      border-radius: .4rem;
      @extend %flex-c-c;
      &:hover {
        border-color: transparent;
        background-color: var(--c-white);
        .btn-text {
          color: var(--c-blue);
        }
      }
    }
  }
  .btn-text {
    @extend %c-white-10;
    @extend %font-14-pr;
  }
}
@media screen and (min-width: $tablet-min-width) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  @each $width in $flex-width {
    .flex#{$width} {
      flex-basis: calc($width * 1%);
      width: calc($width * 1%);
      &-gap {
        flex-basis: calc((#{$width} - var(--quarter-space)) * 1%);
        width: calc((#{$width} - var(--quarter-space)) * 1%);
      }
    }
  }
  .title {
    @include font(24);
  }
  .accordion {
    &-header {
      .btn-text {
        @include font(16);
      }
    }
  }
  .YouTubeModal {
    justify-content: center;
    .modal-wrapper {
      margin-inline: 0;
      max-width: 60%;
    }
    .embed-responsive {
      height: 100%;
    }
  }
  .successModal {
    .modal-body {
      width: 50%;
      margin: var(--two-space) auto 0;
      padding-inline: var(--two-space);
      .form-title {
        font-size: 3.2rem;
      }
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .news-detail-body .main-wrap {
    padding-top: 0;
  }
}
// @media screen and (min-width: $tablet-min-width) and (max-width: $large-desktop-min-width) {}