@use "../config/" as *;
.header-wrap {
	height: var(--header-height);
	z-index: var(--zindex-header);
	backdrop-filter: blur(0.4rem);
	@extend %w-100;
	@extend %c-blue-bg-9;
	@extend %p-x-full;
	@include position(0, null, null, 0, fixed);
	.menu-wrap {
		@extend %flex-n-c;
		@extend %h-100;
	}
	.btn-site-hamburger {
		width: 2rem;
		height: 1.25rem;
		@extend %flex-column-sb-n;
		.line {
			height: 0.2rem;
			@extend %w-100;
			@extend %c-white-bg-10;
			@include transition(500ms ease-in-out);
		}
		&[aria-expanded="true"] {
			@include flex-config(flex, column, space-around, null);
			~ .site-nav {
				right: 0;
				@extend %c-blue-bg-10;
				.social-link-list {
					@include flex-config(flex, null, space-between, center);
				}
			}
			.line {
				transition-property: transform, width, justify-content;
				&:nth-child(1) {
					@include transform(translateY(0.4rem) rotate(45deg));
				}
				&:nth-child(2) {
					@extend %d-none;
				}
				&:nth-child(3) {
					@include transform(translateY(-0.3rem) rotate(-45deg));
				}
			}
		}
	}
}
.site {
	&-header {
		@extend %h-100;
		&-wrap {
			@extend %m-l-auto;
			@extend %flex-n-c;
		}
		.icon-search {
			cursor: pointer;
		}
	}
	&-logo {
		width: var(--logo-width);
		height: var(--logo-height);
		@extend %flex-c-c;
		.logo {
			position: relative;
			background: url('/static-assets/images/cssimages/svg/site-logo.svg?v="#{$image-version}"') no-repeat;
			background-size: contain;
			@extend %flex;
			@extend %h-100;
			@extend %w-100;
			&-text {
				font-size: 0;
				@extend %m-zero;
			}
		}
	}
	&-search {
		@extend %m-r-one-n-half;
		&.active {
			.icon-search {
				&::before {
					content: '\e804';
				}
			}
		}
	}
	&-nav {
		height: calc(var(--window-inner-height) - var(--header-height));
		overflow-y: auto;
		flex-wrap: nowrap;
		transition-property: right;
		@extend %p-x-full;
		@extend %p-b-full;
		@extend %w-100;
		@include transition(500ms ease-in-out);
		@include position(var(--header-height), calc(var(--window-inner-width) * -1), null, null, fixed);
		.navigation {
			&-link-list {
				position: relative;
				@extend %w-100;
			}
			&-link {
				position: relative;
				@extend %w-100;
				@extend %flex-fs-c;
				&.login {
					max-width: 14rem;
					@extend %d-none;
					.nav-anchor {
						text-transform: unset;
						// @include btn(0.1rem solid var(--c-white),hsla(var(--hsl-c-black)/0.6),hsla(var(--hsl-c-white)/0.6));
						@extend .btn-outline;
					}
					.btn-text {
						@extend %font-16-pr;
						@extend %text-center;
						@extend %capitalize;
						@extend %d-block;
						@extend %w-100;
					}
					.nav-text {
						@extend %font-16-pr;
						@extend %text-center;
						@extend %capitalize;
						@extend %d-block;
						@extend %w-100;
					}
				}
				&.profile {
					@extend %d-none;
					.nav-text {
						@extend %c-white-10;
						@extend %capitalize;
						&:before {
							content: '\e811';
							font: 2.4rem $font-icon;
						}
					}
				}
				&.subscribe-btn {
					width: max-content;
					margin-block: var(--full-space);
					// @extend %d-none;
					.nav-anchor {
						border-radius: var(--two-space);
						padding: var(--half-space) var(--two-space);
						border: .1rem solid var(--c-white);
						@extend %c-white-bg-10;
						@extend %p-x-full;
						@extend %p-y-half;
						.nav-text {
							@extend %c-blue-10;
							@extend %capitalize;
						}
						&:hover {
							background: transparent;
							.nav-text {
								@extend %c-white-10;
							}
						}
					}
				}
				.nav-anchor {
					position: relative;
					cursor: pointer;
					white-space: nowrap;
					@extend %w-100;
					@include flex-config(flex, null, flex-start, center);
				}
				&.active {
					.nav-text,
					.btn-text,
					.link-text {
						@extend %c-white-10;
					}
					&.submenu {
						.nav-anchor {
							&::before {
								@include icon('\e819', var(--c-white));
							}
						}
					}
				}
				&.submenu {
					flex-wrap: wrap;
					@include flex-config(flex);
				}
				.btn-nav-anchor {
					&::after {
						@include icon("\e819", 22);
						line-height: 1;
						@include position(null, 0, null, null);
						@extend %c-orange-10;
					}
				}
				.sub {
					&-nav {
						@include transition(all 0.5s ease);
						@extend %w-100;
						@extend %d-none;
					}
					&-nav-link {
						height: 0;
						@extend %flex;
						&.secondary {
							position: relative;
							width: 100%;
							height: max-content;
							[aria-expanded="true"] {
								~ .sub-nav {
									&::before {
										content: '';
										width: calc(100% + calc(2 * var(--two-space)));
										z-index: var(--zindex-pattern);
										@include position(0, null, null, calc(2 * var(--full-space-negative)));
										@extend %c-blue-1-bg-10;
										@extend %h-100;
									}
								}
							}
						}
					}
				}
				[aria-expanded="true"] {
					&.btn-nav-anchor {
						&::after {
							content: "\e85a";
							line-height: 1;
						}
					}
					~ .sub-nav {
						display: block;
						&::before {
							content: '';
							width: calc(100% + var(--two-space));
							z-index: var(--zindex-pattern);
							pointer-events: none;
							@include position(0, null, null, var(--full-space-negative));
							@extend %c-blue-2-bg-10;
							@extend %h-100;
						}
						.sub-nav-link {
							display: block;
							height: max-content;
						}
					}
				}
			}
		}
		.btn-nav-anchor,
		.nav-anchor,
		.sub-nav-anchor {
			display: inline-block;
			font: 2rem/1.2 $font-psb;
			@extend %w-100;
			@extend %uppercase;
		}
		.nav-anchor,
		.btn-nav-anchor {
			padding-block: 2rem;
			@extend %c-white-6;
		}
		.btn-nav-anchor {
			@extend %text-left;
		}
		.active > .sub-nav-anchor {
			color: var(--c-orange);
		}
		.sub-nav-anchor, .sub-nav .btn-nav-anchor {
			padding-block: 1.2rem;
			font-size: 1.6rem;
			@extend %c-white-10;
		}
		.sub-nav .sub-nav {
			.sub-nav-anchor,
			.btn-nav-anchor {
				padding-block: 1rem;
				font-size: 1.4rem;
			}
		}
	}
}
.homepage {
	&.iPhone {
		.site-nav {
			top: calc(var(--header-height) + var(--eventstrip));
		}
		&.no-live-event {
			.site-nav {
				height: calc(var(--window-inner-height) - var(--header-height));
				top: var(--header-height);
			}
		}
	}
	.site-nav {
		height: calc(var(--window-inner-height) - var(--header-height) - var(--eventstrip));
	}
	&.no-live-event {
		.site-nav {
			height: calc(var(--window-inner-height) - var(--header-height));
			top: var(--header-height);
		}
	}
}
@media (min-width:$tablet-min-width) {
	.homepage, .news-detail-body {
		.header-wrap {
			@include bg(c-blue, 7);
		}
	}
	.homepage {
		.site-nav {
			height: 100%;
			top: 0;
		}
		&.no-live-event .site-nav {
			height: 100%;
			top: 0;
		}
	}
	.scorestrip-show .site-nav .navigation-link > [aria-expanded=true]~.sub-nav {
		max-height: calc(var(--window-inner-height) - var(--header-height) - var(--eventstrip));
	}
	.header-wrap {
		padding-inline: var(--two-space);
		@include bg(c-blue, 9);
		.btn-site-hamburger {
			display: none;
		}
		.site-header-wrap {
			height: 3.8rem;
			margin-left: unset;
			width: 100%;
			overflow: scroll;
			@include flex-config(flex, row-reverse);
		}
	}
	.site-search {
		margin-right: 0;
	}
	.site-nav {
		max-width: 60rem;
		height: 100%;
		overflow: auto;
		padding: 0;
		margin-inline: var(--half-space);
		@include position(0, unset, null, null, relative);
		.navigation {
			&-link {
				width: max-content;
				margin-inline: 2rem;
				// margin-inline: var(--two-space);
				&.login {
					.nav-anchor {
						padding: calc(var(--half-space)/2) calc(3 * var(--half-space));
						&:hover {
							padding: calc(var(--half-space)/2) calc(3 * var(--half-space));
						}
					}
				}
				> [aria-expanded="true"] {
					~ .sub-nav {
						width: 100%;
						max-height: calc(var(--window-inner-height) - var(--header-height));
						padding: 4rem 6.4rem var(--two-space);
						@include position(var(--header-height), null, null, 0, fixed);
						@include flex-config(flex, null, null, flex-start);
						&::before {
							background: var(--c-blue);
						}
						.sub-nav-link {
							flex-shrink: 0;
							flex: 1;
						}
					}
				}
				.sub-nav-link {
					&.secondary {
						.sub-nav {
							display: block;
							padding: 0;
						}
						> [aria-expanded="true"] ~ .sub-nav::before {
							content: unset;
						}
					}
				}
				&.submenu {
					.btn-nav-anchor {
						&::after {
							content: unset;
						}
					}
				}
				&.right-align {
					margin-right: auto;
				}
				// &.login,
				// &.profile {
				//     margin-left: auto;
				// }
				&.subscribe-btn {
					// margin-left: auto;
					margin-block: 0;
					.nav-anchor {
						padding-inline: var(--full-space);
					}
				}
				&:is(.subscribe-btn) ~ .login,
				&:is(.subscribe-btn) ~ .profile {
					margin-left: unset;
				}
			}
			&-link-list {
				height: 100%;
				padding-bottom: 0;
				@include flex-config(flex);
				.nav-text,
				.btn-text {
					white-space: nowrap;
				}
			}
		}
		.nav-anchor, .btn-nav-anchor {
			padding-block: 0;
			font-size: 1.6rem;
		}
		.nav-anchor, .sub-nav-anchor {
			&:hover {
				color: var(--c-orange);
			}
		}
		.sub-nav {
			.btn-nav-anchor, .sub-nav-anchor {
				padding-block: 0;
			}
		}
		.btn-nav-anchor[aria-expanded='true'] {
			color: var(--c-pure-white);
		}
		.sub-nav-anchor {
			color: hsl(var(--hsl-c-white) / .8);
			&:hover {
				color: var(--c-orange);
			}
		}
		.secondary {
			.sub-nav {
				margin-top: 2rem;
			}
			.sub-nav-link:not(:last-child) {
				margin-bottom: 2rem;
			}
		}
	}
}
@media (min-width:$desktop-min-width) {
	.site-nav {
		max-width: 100%;
	}
}
@media (min-width:$large-desktop-min-width) {
	.header-wrap {
		.site-header-wrap {
			overflow: unset;
		}
	}
}