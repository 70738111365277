@use "../config/" as *;
.waf-eventstrip.waf-event {
    @each $event, $event-key in $events {
        @include event-card($event, $event-key);
    }
    @extend %p-half;
    @extend %m-y-three-space;
    @extend %head-position;
    .waf-head {
        @extend %font-20-pb;
        @extend %c-blue-10;
        @extend %m-b-two-space;
    }
    .event {
        &-thumb {
            height: 100%;
            overflow: hidden;
            border-radius: var(--half-radius);
            &::after {
                background: linear-gradient(246deg, hsl(var(--hsl-c-institutional-primary) / .6) 4.99%, var(--c-institutional-secondary) 100.98%);
            }
            .image {
                border-radius: var(--half-radius);
                height: 100%;
                object-fit: cover;
                @include transition(all 1s);
            }
        }
        &-content {
            // position: relative;
            z-index: var(--zindex1);
            height: calc(100% - 2.1rem);
            width: 80%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            @extend %flex-column-c-c;
            @extend %c-pure-white-10;
        }
        &-name {
            @include font(20, 20, $font-pb);
            line-height: 1;
            // @extend %font-20-pb;
            @extend %m-y-half;
            @extend %c-pure-white-10;
            @extend %text-center;
        }
        &-venue {
            order: 0;
            @extend %font-14-pr;
        }
        &-meta {
            .meta {
                @extend %font-14-pb;
            }
        }
        &-wrap {
            @extend %h-100;
        }
        &-action {
            z-index: var(--zindex1);
            @include position-combo(inset);
            .text {
                @extend %font-zero;
            }
        }
        &-item {
            height: 18rem;
            @extend %c-blue-bg-10;
            @include border-radius(var(--half-radius));
            &::after {
                content: "";
                position: absolute;
                inset: 0;
                @include border-radius(var(--half-radius));
                @extend %c-pure-black-bg-2;
            }
            &:hover {
                .image {
                    transform: scale(1.2);
                }
            }
        }
    }
    .card-list {
        @extend %flex-column;
    }
    .layout-wrapper {
        max-width: 100%;
    }
    .event-item {
        &.olympic-qualifying-tournament, &.olympic-games {
            .event-badge {
                background:url('/static-assets/images/cssimages/svg/events/olympic-qualifying-tournament-logo.svg?v=#{$image-version}') no-repeat;
                background-size: contain;
            }
        }
    }
    .olympic-qualifying-tournament, .olympic-games {
        .event-venue,
        .event-name {
            @extend %c-black-8;
        }
        .event-meta {
            .meta {
                @extend %c-black-8;
            }
        }
    }
}
.watch-center {
    .event-strip {
        @extend %c-blue-bg-10;
    }
    .waf-eventstrip.waf-event {
        @extend %p-x-zero;
        @extend %m-x-half-neg;
    }
    .waf-eventstrip {
        @extend %c-blue-bg-10;
        .waf-head {
            @extend %p-x-full;
        }
        .card-list {
            flex-direction: row;
        }
        .waf-head {
            .title {
                @extend %c-white-10;
            }
            .head-tab {
                @extend %d-none;
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            @extend %d-none;
        }
    }
}
.uww-plus-body .event-strip {
    background-color: var(--c-blue-3);
}
@media (min-width:$tablet-min-width) {
    .waf-eventstrip.waf-event {
        padding: 0;
        .waf-head {
            .head-tab {
                // display: block;
                position: unset;
                @include translate(0, 0);
            }
        }
        .card-list {
            @include grid(repeat(2, 1fr));
        }
        .event-name {
            margin-block: var(--full-space);
            @include font(24);
            line-height: 1;
        }
        .event-venue,
        .event-meta .meta {
            font-size: 1.6rem;
        }
    }
    .watch-center {
        .waf-eventstrip.waf-event {
            padding-left: var(--half-space);
            max-width: unset;
            .layout-wrapper {
                margin-left: calc(var(--container-white-space));
            }
        }
        .waf-eventstrip {
            &.tab-container-wrap-item .waf-head {
                max-width: unset;
                margin: 0;
                .head-tab {
                    display: flex;
                }
            }
            .card-list {
                display: flex;
                gap: 0;
            }
            .waf-head {
                max-width: var(--container-max-width);
                margin: 0 var(--container-white-space) var(--two-space) 0;
                padding-inline: 0;
                .head-tab {
                    display: block;
                }
            }
            .swiper-button-next,
            .swiper-button-prev {
                display: flex;
                transform: translateY(-50%);
                &::after {
                    @include font(30, 30);
                    @include color(c-white);
                }
            }
            .swiper-button-prev.swiper-button-disabled {
                opacity: 0;
            }
            .swiper-button-next.swiper-button-disabled {
                opacity: 0;
            }
        }
    }
}
@media (min-width:$desktop-min-width) {
    .watch-center {
        .waf-eventstrip.waf-event {
            // margin-inline: 0;
            max-width: unset
        }
    }
    .waf-eventstrip.waf-event {
        margin: calc(5 * var(--full-space)) auto;
        max-width: var(--container-max-width);
        .card-list {
            @include grid(repeat(3, 1fr));
        }
        .event-item {
            height: auto;
            min-height: 21rem;
            aspect-ratio: 1.98;
        }
    }
}